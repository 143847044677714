// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".listview{width:100%;overflow:auto}.listview--align-horizontally-left .k-listview-content{justify-content:flex-start;display:flex;flex-wrap:wrap}.listview--align-horizontally-center .k-listview-content{justify-content:center;display:flex;flex-wrap:wrap}.listview--align-horizontally-right .k-listview-content{justify-content:flex-end;display:flex;flex-wrap:wrap}.listview-item{opacity:1;cursor:pointer;position:relative;border:rgba(0,0,0,0) 1px solid}.listview-item:hover{border:#4d4d4d 1px solid}.layout-editor .drop-zone .control-group.grid__atom .control-wrapper:has(.listview-wrapper){z-index:auto}.layout-editor .drop-zone .control-group.grid__atom.grid-control-group-wrapper>.control-and-actions-container>.control-wrapper:has(.listview-wrapper){width:100%;padding-left:0;padding-right:0}.layout-editor .drop-zone .control-group.grid__atom.grid-control-group-wrapper>.control-and-actions-container>.control-wrapper:has(.listview-wrapper) .listview-wrapper{margin-left:10px;width:calc(100% - 10px)}.listview-wrapper .k-toolbar.k-grid-toolbar.k-toolbar-md{border-radius:5px 5px 0 0}.listview-wrapper .toolbar_element_wrapper{overflow:visible}.listview-wrapper .k-toolbar{z-index:auto}.listview-wrapper{border:1px solid #b3b3b3;border-top-left-radius:5px;border-top-right-radius:5px;width:100%}.listview-wrapper .k-toolbar{background-color:rgba(0,0,0,0);border:0;border-bottom:2px solid #8c8c8c}.listview-wrapper .k-toolbar .toolbar_element_wrapper>button.k-button,.listview-wrapper .k-toolbar .toolbar_element_wrapper>label.label{margin-left:2px}.layout-editor .base-layout-editor-wrapper .listview-wrapper .k-toolbar .drop-zone-container .drop-zone.toolbar-drop-zone{min-height:30px;box-shadow:none}.layout-editor .base-layout-editor-wrapper .listview-wrapper .k-toolbar.k-toolbar-md{padding:8px;border-bottom:0}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
