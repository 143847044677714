// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.control-group--inline-label .control-group__field--wide{flex:1}.k-combobox .k-input span:first-child{text-overflow:ellipsis}.k-combobox .k-i-loading{align-self:center}.no-results-icon{color:#3065ee;font-size:25px}.action-button.k-button.k-button-solid-base{border:.5px solid #4d4d4d !important;margin-left:8px;width:30px;padding:4px;flex-shrink:0}.action-button.k-button.k-button-solid-base:focus{box-shadow:none}.reference-wrapper{display:flex;width:100%}.reference-wrapper .k-button:hover{background-color:#d9d9d9}.reference-wrapper .k-button:hover .k-button-text,.reference-wrapper .k-button:hover .k-button-text i{background-color:rgba(0,0,0,0)}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
