// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.swiper-cnt{position:relative;min-height:256px;width:100%;margin-top:100px;max-width:1440px;padding:0 96px;box-sizing:border-box}.swiper-cnt__button{position:absolute;top:0;bottom:0;margin:auto;display:flex;justify-content:center;align-items:center;cursor:pointer;transition:opacity .2s;background-color:#e8751c;height:48px;width:48px;border-radius:55px;font-size:19px;color:#fff}.swiper-cnt__button:hover{opacity:.9}.swiper-cnt__button--prev{left:0}.swiper-cnt__button--next{right:0}.swiper-cnt__item{display:flex;flex-flow:column;gap:14px}.swiper-button-disabled{pointer-events:none;opacity:.2}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
