// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.expression-field{display:flex;height:30px;width:100%;border:.5px solid #4d4d4d;border-radius:5px}.expression-field--icon{width:30px;text-align:center;border-right:.5px solid #4d4d4d;border-radius:5px;line-height:30px}.expression-field:hover,.expression-field:focus-within{border:.5px solid var(--header-background-color)}.expression-field .k-textbox{font-family:monospace}.expression-field .k-textbox,.expression-field .k-button{border:0}.expression-field .k-textbox:hover,.expression-field .k-textbox:focus,.expression-field .k-button:hover,.expression-field .k-button:focus{border:0}.expression-field .k-button.k-button-solid-base:focus{box-shadow:none}.expression-field .k-button .k-button-text{display:inline-flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
