// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.autosave-indicator{display:inline-flex;flex-direction:column;align-items:center;margin-left:auto;padding:0 10px}.autosave-indicator__header{display:flex;align-items:center;gap:6px;width:100%;padding:0 6px;box-sizing:border-box;user-select:none;height:24px;border-radius:6px}.autosave-indicator__icon{flex-shrink:0}.autosave-indicator__title{font-size:0.8125rem}.autosave-indicator__button{display:inline-flex;align-items:center}.autosave-indicator__button--active{cursor:pointer}.autosave-popover{opacity:0;animation:fadeIn .25s forwards}.autosave-popover__container{box-shadow:0 4px 9px rgba(0,0,0,.2588235294);border-radius:6px;overflow:hidden}.autosave-popover__content{background-color:#fff;padding:10px 18px;font-size:0.75rem;max-height:300px;overflow:auto;display:flex;flex-direction:column;gap:6px}.autosave-popover__content p{margin:0}.autosave-popover.k-animation-container-shown{border:0}.autosave-popover .k-popup{border:0;box-shadow:none;border-radius:6px}.autosave-popover .k-popover-body{padding:0}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
