// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.editor-toolbar.k-toolbar.page-toolbar{width:100%;border-bottom:0;height:auto}.page-toolbar.page-toolbar--dark.k-toolbar{background-color:#f3f3f3;padding-left:30px;padding-right:30px;margin-left:0;width:100%}.page-toolbar__label{margin-left:10px;margin-bottom:8px;margin-top:10px}.page-toolbar__label .label{flex:unset}.page-toolbar.k-toolbar{position:sticky;top:0;z-index:2004;flex-shrink:0;border-width:0;border-bottom:1px solid #d9d9d9;display:flex;flex-flow:column;align-items:flex-start;justify-content:flex-end;height:100px;background-color:#fff;padding:12px 15px;width:calc(100% - 30px)}.page-toolbar.k-toolbar:before{display:none}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
