// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.icon-picker{display:flex;align-items:center;justify-content:flex-start;min-width:82px;height:32px;padding:0;flex-direction:row}.icon-picker__clear-button{padding:0 4px;margin-left:4px}.icon-picker .icon-picker-button{display:flex;align-items:center;justify-content:space-between;background-color:#fff;outline:none;min-width:82px;padding:8px 0 8px 8px;height:31px;flex-direction:row;flex-wrap:nowrap;overflow:hidden}.icon-picker .icon-picker-button__arrow{cursor:pointer;background:rgba(0,0,0,0);border:.5px solid rgba(0,0,0,0)}.icon-picker .icon-picker-button__arrow .k-icon{padding-top:2px}.icon-picker .icon-picker-button__arrow:hover{background-color:#d9d9d9;border-color:#4d4d4d;border-radius:6px}.icon-picker .icon-picker-button .icon-picker-icon{font-size:24px;margin:0 10px}.icon-picker .icon-picker-button .icon{font-size:20px}.icon-picker-popup{z-index:20003 !important;overflow:hidden}.icon-picker-popup__inner{z-index:20003;width:340px;display:flex;flex-direction:row;flex-wrap:wrap;max-height:200px;overflow-y:auto}.icon-picker-popup__icon{width:16.6666666667%;height:60px;display:flex;justify-content:center;align-items:center;cursor:pointer}.icon-picker-popup__icon:hover{background-color:#d8d8d8;transition:background-color 200ms linear}.icon-picker-popup__icon .icon-picker-icon{font-size:36px}.icon-picker-popup .search-input,.icon-picker-popup .search-results{margin:10px;font-family:\"Ubuntu\",sans-serif}.icon-picker-popup .search-results{font-size:16px;text-align:center;width:100%;margin-bottom:20px}.icon{font-size:22px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
