// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.env-content-wrapper{box-sizing:border-box;margin:130px auto;width:100%;max-width:1440px;padding:0 20px}@media(min-width: 1025px){.env-content-wrapper{padding:0 60px}}.env-content{width:100%;box-sizing:border-box;align-items:center;display:flex;flex-flow:column}.env-content__header{width:100%;display:flex;flex-flow:column;align-items:center;gap:16px;padding:0 100px;box-sizing:border-box}@media(min-width: 1025px){.env-content__header{flex-flow:row}}.env-content__input{background-color:#fff;height:34px;color:#4d4d4d;border:1px solid #4d4d4d;font-size:0.875rem;border-radius:34px;padding:0 44px 0 34px;box-sizing:border-box;outline:none;width:256px}.env-content__input::placeholder{color:#4d4d4d}.env-content__input-container{position:relative}@media(min-width: 1025px){.env-content__input-container{margin-left:auto}}.env-content__input-search{position:absolute;pointer-events:none;right:0;top:0;bottom:0;margin:auto 12px auto auto;display:flex;align-items:center;justify-content:center;padding:0 10px;font-size:15px;transform:rotate(270deg)}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
