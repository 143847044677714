// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }


const moo = require('moo')

const LETTER_REGEXP = /[a-zA-Z]/;
const isCharLetter= (char) => LETTER_REGEXP.test(char);

function textToCaseInsensitiveRegex(text) {
    const regexSource = text.split('').map((char) => {
        if (isCharLetter(char)) {
            return `[${char.toLowerCase()}${char.toUpperCase()}]`;
        }
        return char;
    }).join('');

    // Add a negative lookahead to ensure the match is not followed by an alphanumeric character.
    return new RegExp(`${regexSource}(?!\\w)`);
}

function textArrayToCaseInsensitiveRegexArray(array){
    return array.map((value)=> textToCaseInsensitiveRegex(value));
}

let lexer = moo.compile({
    space: {match: /\s+/, lineBreaks: true},
    number: /-?(?:[0-9]|[1-9][0-9]+)(?:\.[0-9]+)?(?:[eE][-+]?[0-9]+)?\b/,
    string: /"(?:\\["bfnrt\/\\]|\\u[a-fA-F0-9]{4}|[^"\\])*"/,
    stringWithSingleQuotes: /'(?:\\['bfnrt\/\\]|\\u[a-fA-F0-9]{4}|[^'\\])*'/,
    '{{': '{{',
    '}}': '}}',
    '(': '(',
    ')': ')',
    '[': '[',
    ']': ']',
    '{': '{',
    '}': '}',
    ',': ',',
    true: textToCaseInsensitiveRegex('true'),
    false: textToCaseInsensitiveRegex('false'),
    null: textToCaseInsensitiveRegex('null'),
    undefined: textToCaseInsensitiveRegex('undefined'),
    andOperator: textToCaseInsensitiveRegex('AND'),
    orOperator: textToCaseInsensitiveRegex('OR'),
    blankOperator: textArrayToCaseInsensitiveRegexArray(['ISBLANK', 'NOT ISBLANK']),
    containsOperator: textArrayToCaseInsensitiveRegexArray(['NOT CONTAINS', 'CONTAINS']),
    betweenOperator: textToCaseInsensitiveRegex('BETWEEN'),
    simplePredicateOperator: ([
      textToCaseInsensitiveRegex('IS NOT'), textToCaseInsensitiveRegex('IS'), '<>', '!=', '>=', '<=', '=', '>', '<'
    ]),
    arrayPredicateOperator: textArrayToCaseInsensitiveRegexArray(['NOT IN', 'IN']),
    fieldName: /[_a-zA-Z0-9]+/,
    other: /[^_a-zA-Z0-9{}\n]+/, // Anything else
})




function extractArray(d) {
    let output = [d[2]];

    for (let i in d[3]) {
        output.push(d[3][i][3]);
    }

    return output;
}

function trimSpaces(spacedOperator) {
  return spacedOperator.trim();
}

function constructTextFromArray(array) {
    let result = '';

    // Helper function to recursively process the array
    function processItem(item) {
        if (Array.isArray(item)) {
            // Recursively process each element of the array
            item.forEach(subItem => processItem(subItem));
        } else if (item && typeof item === 'object' && 'text' in item){
            // Concatenate the text value of the object
            result += item.text;
        }else if(typeof item === 'string'){
            // Return the string
            result += item;
        }
    }

    // Start processing the main array
    processItem(array);

    return result;
}
var grammar = {
		Hash: '72a260be81b3595676877498e9981241660b6d44f78b062f9308c659497f01c3',
    Lexer: lexer,
    ParserRules: [
    {"name": "ast", "symbols": ["_", "or", "_"], "postprocess": (d) => d[1]},
    {"name": "fieldName", "symbols": [(lexer.has("fieldName") ? {type: "fieldName"} : fieldName)], "postprocess": (d) => d[0].value},
    {"name": "fieldName", "symbols": [(lexer.has("stringWithSingleQuotes") ? {type: "stringWithSingleQuotes"} : stringWithSingleQuotes)], "postprocess": (d) => d[0].value.replace(/'/g, '')},
    {"name": "fieldName", "symbols": ["bsExpression"], "postprocess": id},
    {"name": "filterPart", "symbols": [{"literal":"("}, "_", "or", "_", {"literal":")"}], "postprocess": (d) => d[2]},
    {"name": "filterPart", "symbols": ["simplePredicate"], "postprocess": id},
    {"name": "filterPart", "symbols": ["arrayPredicate"], "postprocess": id},
    {"name": "filterPart", "symbols": ["stringInclusion"], "postprocess": id},
    {"name": "filterPart", "symbols": ["between"], "postprocess": id},
    {"name": "filterPart", "symbols": ["bsExpression"], "postprocess": id},
    {"name": "and", "symbols": ["and", "_", (lexer.has("andOperator") ? {type: "andOperator"} : andOperator), "_", "filterPart"], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'andOr',
          left: d[0],
          operator: trimSpaces(d[2].value.toUpperCase()),
          right: d[4]
        }) },
    {"name": "and", "symbols": ["filterPart"], "postprocess": id},
    {"name": "or", "symbols": ["or", "_", (lexer.has("orOperator") ? {type: "orOperator"} : orOperator), "_", "and"], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'andOr',
          left: d[0],
          operator: trimSpaces(d[2].value.toUpperCase()),
          right: d[4]
        }) },
    {"name": "or", "symbols": ["and"], "postprocess": id},
    {"name": "stringInclusion", "symbols": [(lexer.has("containsOperator") ? {type: "containsOperator"} : containsOperator), {"literal":"("}, "_", "fieldName", "_", {"literal":","}, "_", "value", "_", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'predicate',
          fieldName: d[3],
          operator: trimSpaces(d[0].value.toUpperCase()),
          value: d[7]
        }) },
    {"name": "stringInclusion", "symbols": [(lexer.has("blankOperator") ? {type: "blankOperator"} : blankOperator), {"literal":"("}, "_", "fieldName", "_", {"literal":")"}], "postprocess":  (d) => ({
         _bsInternal: true,
         type: 'predicate',
         fieldName: d[3],
         operator: trimSpaces(d[0].value.toUpperCase()),
        }) },
    {"name": "between", "symbols": ["fieldName", "_", (lexer.has("betweenOperator") ? {type: "betweenOperator"} : betweenOperator), "_", "value", "_", (lexer.has("andOperator") ? {type: "andOperator"} : andOperator), "_", "value"], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'predicate',
          fieldName: d[0],
          operator: trimSpaces(d[2].value.toUpperCase()),
          value1: d[4],
          value2: d[8]
        }) },
    {"name": "simplePredicate", "symbols": ["fieldName", "_", (lexer.has("simplePredicateOperator") ? {type: "simplePredicateOperator"} : simplePredicateOperator), "_", "value"], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'predicate',
          fieldName: d[0],
          operator: trimSpaces(d[2].value.toUpperCase()),
          value: d[4]
        }) },
    {"name": "arrayPredicate$subexpression$1", "symbols": ["value"]},
    {"name": "arrayPredicate$subexpression$1", "symbols": ["array"]},
    {"name": "arrayPredicate", "symbols": ["fieldName", "_", (lexer.has("arrayPredicateOperator") ? {type: "arrayPredicateOperator"} : arrayPredicateOperator), "_", "arrayPredicate$subexpression$1"], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'predicate',
          fieldName: d[0],
          operator: trimSpaces(d[2].value.toUpperCase()),
          value: d[4][0]
        }) },
    {"name": "value", "symbols": ["bsExpression"], "postprocess": id},
    {"name": "value", "symbols": ["number"], "postprocess": id},
    {"name": "value", "symbols": ["string"], "postprocess": id},
    {"name": "value", "symbols": ["stringWithSingleQuotes"], "postprocess": id},
    {"name": "value", "symbols": [{"literal":"true"}], "postprocess": function(d) { return true; }},
    {"name": "value", "symbols": [{"literal":"false"}], "postprocess": function(d) { return false; }},
    {"name": "value", "symbols": [{"literal":"null"}], "postprocess": function(d) { return null; }},
    {"name": "value", "symbols": [{"literal":"undefined"}], "postprocess": function(d) { return undefined; }},
    {"name": "number", "symbols": [(lexer.has("number") ? {type: "number"} : number)], "postprocess": function(d) { return parseFloat(d[0].value) }},
    {"name": "string", "symbols": [(lexer.has("string") ? {type: "string"} : string)], "postprocess":  function(d) {
          return d[0].value.slice(1, d[0].value.length - 1);
        } },
    {"name": "stringWithSingleQuotes", "symbols": [(lexer.has("stringWithSingleQuotes") ? {type: "stringWithSingleQuotes"} : stringWithSingleQuotes)], "postprocess":  function(d) {
          return d[0].value.slice(1, d[0].value.length - 1);
        } },
    {"name": "array", "symbols": [{"literal":"["}, "_", {"literal":"]"}], "postprocess": function(d) { return []; }},
    {"name": "array$ebnf$1", "symbols": []},
    {"name": "array$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "value"]},
    {"name": "array$ebnf$1", "symbols": ["array$ebnf$1", "array$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "array", "symbols": [{"literal":"["}, "_", "value", "array$ebnf$1", "_", {"literal":"]"}], "postprocess": extractArray},
    {"name": "bsExpression", "symbols": [{"literal":"{{"}, "_", "bsExpressionContent", "_", {"literal":"}}"}], "postprocess":  (d) => ({
          _bsInternal: true,
          type: 'bsExpression',
          expression: d[2],
          text: `{{${d[2]}}}`
        })},
    {"name": "bsExpressionContent$ebnf$1", "symbols": []},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": ["bsExpression"]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("space") ? {type: "space"} : space)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("number") ? {type: "number"} : number)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("string") ? {type: "string"} : string)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("stringWithSingleQuotes") ? {type: "stringWithSingleQuotes"} : stringWithSingleQuotes)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":"("}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":")"}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":"["}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":"]"}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":"{"}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":"}"}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [{"literal":","}]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("true") ? {type: "true"} : true)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("false") ? {type: "false"} : false)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("null") ? {type: "null"} : null)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("undefined") ? {type: "undefined"} : undefined)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("fieldName") ? {type: "fieldName"} : fieldName)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("andOperator") ? {type: "andOperator"} : andOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("orOperator") ? {type: "orOperator"} : orOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("blankOperator") ? {type: "blankOperator"} : blankOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("containsOperator") ? {type: "containsOperator"} : containsOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("betweenOperator") ? {type: "betweenOperator"} : betweenOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("simplePredicateOperator") ? {type: "simplePredicateOperator"} : simplePredicateOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("arrayPredicateOperator") ? {type: "arrayPredicateOperator"} : arrayPredicateOperator)]},
    {"name": "bsExpressionContent$ebnf$1$subexpression$1", "symbols": [(lexer.has("other") ? {type: "other"} : other)]},
    {"name": "bsExpressionContent$ebnf$1", "symbols": ["bsExpressionContent$ebnf$1", "bsExpressionContent$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "bsExpressionContent", "symbols": ["bsExpressionContent$ebnf$1"], "postprocess": d => constructTextFromArray(d)},
    {"name": "_", "symbols": []},
    {"name": "_", "symbols": [(lexer.has("space") ? {type: "space"} : space)], "postprocess": function(d) { return null; }}
]
  , ParserStart: "ast"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
