// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.editor-toolbar .toolbar__container,.editor-toolbar .toolbar_element_wrapper{height:unset}.layout-editor .toolbar_element_wrapper{height:unset}.editor-grid-toolbar .k-grid-toolbar>.toolbar__container{height:unset}.toolbar__container{width:100%;height:30px;align-items:center}.toolbar__container>.toolbar_element_wrapper>.label{width:unset;min-width:unset;padding-left:6px;padding-right:8px;line-height:30px;flex:1}.toolbar_group_separator{padding-right:10px}.toolbar_group_separator:not(:last-child){padding-right:0}.toolbar_element_wrapper{flex-flow:row wrap;overflow:hidden;display:flex;width:100%;height:30px}.toolbar_element_wrapper>button.k-button,.toolbar_element_wrapper>label.label{margin:0 8px}.toolbar_more_button_wrapper{padding-left:10px}.aeroToolbarMore{background-color:inherit;min-height:30px}.aeroToolbarMore__label>span{color:var(--header-background-color)}.aeroToolbarMore .k-icon{order:2;margin:0}.toolbar__container.toolbar-picklist-wrapper{height:auto}.toolbar__container.toolbar-picklist-wrapper .control-group.grid__atom{overflow:unset;margin:0}.toolbar__container.toolbar-picklist-wrapper .toolbar_element_wrapper{justify-content:space-between;align-items:center;overflow:unset}.toolbar__container.toolbar-picklist-wrapper .control-group .label{flex:none;font-size:14px;color:#4d4d4d;margin-right:4px}.toolbar__container.toolbar-picklist-wrapper .control-group .label--textAlign-left{flex:1}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
