// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}.resizable-pane{display:flex;flex-direction:row;align-items:stretch;width:fit-content}.resizable-pane>.content{overflow:auto}.container-bar{position:relative}.container-bar--collapsed{margin-left:24px}.line-bar{background-color:#8c8c8c;width:1px;box-shadow:0 2px 4px rgba(0,0,0,.05);height:100%;display:flex;align-items:center;flex-direction:column}.line-bar--resize{cursor:col-resize}.line-bar-button{cursor:pointer;user-select:none;box-sizing:border-box;width:24px;height:24px;color:#8c8c8c;background-color:#fff;border:1px solid #8c8c8c;border-radius:16px;display:flex;align-items:center;justify-content:center;position:absolute;left:-12px;top:28px;z-index:2005;transition:background-color .2s}.line-bar-button:hover{background-color:#f3f3f3}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
