// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".k-notification-group.bizzstream-notification-group{font-family:\"Ubuntu\",sans-serif;font-size:14px;z-index:11003;bottom:0;right:0;width:calc(100% - 20px);margin:15px 10px}.k-notification-group.bizzstream-notification-group .k-animation-container{width:100%}@media(min-width: 576px){.k-notification-group.bizzstream-notification-group{width:380px}}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
